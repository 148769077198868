import { convert_datetime } from './convert_datetime.js';
const { reduce, defaultTo, values } = require('lodash');

(function ( $ ) {

    var methods = {
        init : function(options)
        {
            var settings = getSettings(options);

            initButtons(settings, $(this));
            initCheckboxes($(this));

            // Initialises datatable with buttons and checkboxes.
            var table = $(this).DataTable({
                'saveState': true,
                columnDefs: [ {
                    orderable: false,
                    targets:   [0, 1]
                } ],
                order: [[ 2, 'asc' ]],
                buttons: [
                            {
                                extend: 'colvis',
                                text: 'Column Visibility',
                                titleAttr: 'Col visibility',
                                className: 'btn-secondary main-btn'
                            },
                            {
                                extend: 'csvHtml5',
                                text: 'CSV',
                                titleAttr: 'Generate CSV',
                                className: 'btn-secondary dt-btn'
                            },
                            {
                                extend: 'copyHtml5',
                                text: 'Copy',
                                titleAttr: 'Copy to clipboard',
                                className: 'btn-secondary dt-btn'
                            },
                            {
                                extend: 'print',
                                text: 'Print',
                                titleAttr: 'Print Table',
                                className: 'btn-secondary dt-btn'
                            }
                        ],
                "drawCallback": function( settings ) {
                    // Checks/unchecks master checkbox when changing pages in data table
                    var numberOfCheckboxes = $(".sub-checkbox").length;
                    var numberOfCheckboxesChecked = $('.sub-checkbox:checked').length;
                    if (numberOfCheckboxes == numberOfCheckboxesChecked && numberOfCheckboxes != 0) {
                        $(".check-all").prop("checked", true);
                    } else {
                        $(".check-all").prop("checked", false);
                    }
                }
            });


            // Append buttons to datatable
            //$('#' + table.table().container().id).prepend('<div class="col-sm-6 pl-0 pb-3"></div>');

            //table.buttons().container().appendTo( '#' + table.table().container().id + ' .col-sm-6');

            table.buttons().container().appendTo(settings.ids.buttonsContainerId);

            // Add scroll when horizontal overflow occurs.
            console.log($(table.table().container()).find('tbody').closest('.row').addClass('h-overflow-scroll'));

            // When using the search clear checkboxes
            table.on('search.dt', function () {
                $(".check-all").prop("checked", false);
                $(".sub-checkbox").prop("checked", false);
            } );

            addModalEvents(table, settings);
            initOverrideActions(table, settings);
            initPreviewDeleted(table, settings);

            return this;
        },
        // Need to make api request for soft deleted rows and add them to table
        show_soft_deleted : function(options) {

            var settings = getSettings(options);

            var table = $(this).DataTable();

            let url = settings.urls["getSoftDeleted"];
            if (settings.multitenancy == false)
            {
                url += '?tenancy=false';
            }

            $.ajax({
                url: url,
                type: 'GET',
                headers: {
                    "X-CSRF-TOKEN" : $('meta[name="csrf-token"]').attr('content')
                },
                success: function(data) {
                    console.log(data);
                    data = data["data"]
                    // Now we want to add the rows on client side
                    for (let i = 0; i < data.length; ++i) {
                        insertRow(data[i], table, settings, true);
                    }
                },
                error: function(error) {
                    let message = error.responseJSON.message;
                    displayToast(settings.ids.toastId, message, 3000);
                }
            });

        },

        hide_soft_deleted : function()
        {
            var table = $(this).DataTable();

            let rows = $(table.table().container()).find("tr.soft-deleted");

            // This doesn't work cause even when height is 0 the row does not shrink.
            /*rows.animate({height: 0, opacity: 0}, 500, function() {
                console.log("DONE");
            })*/

            rows.fadeOut(500, function() {
                table.rows("tr.soft-deleted").remove().draw(false);
            });
        },

        delete_selected_rows : function(options)
        {
            var settings = getSettings(options);

            var table = $(this).DataTable();

            let selectedIds = [];

            table.$('input[type="checkbox"]').each(function() {
                if (this.checked)
                {
                    selectedIds.push(this.value);
                }
            });

            let values = {
                "selected_ids": selectedIds
            }

            if (selectedIds.length != 0)
            {
                let url = settings.urls["bulkDelete"];
                if (settings.multitenancy == false)
                {
                    url += '?tenancy=false';
                }

                $.ajax({
                    url: url,
                    type: 'DELETE',
                    headers: {
                        "X-CSRF-TOKEN" : $('meta[name="csrf-token"]').attr('content')
                    },
                    data: values,
                    success: function(response) {
                        console.log(response);
                        let showSoftDeleted = $(settings.ids.showSoftDeletedId).data("soft-deleted");

                        // If the show soft deleted button is clicked we need to insert the rows we just deleted.
                        if (showSoftDeleted)
                        {
                            for (let j = 0; j < response["data"].length; j++)
                            {
                                let id = response["data"][j]["attributes"]["id"];
                                let domRow = $(table.table().container()).find("#row-id-" + id).first();

                                $(domRow).fadeOut(500, function() {
                                    let row = table.row("#row-id-" + id);
                                    row.remove();
                                    insertRow(response["data"][j], table, settings, true);
                                });
                            }
                        }
                        else
                        {
                            // Need to delete rows on the client side.
                            for (let i = 0; i < selectedIds.length; ++i) {
                                deleteRow(selectedIds[i], table);
                            }
                        }
                        //table.draw(false);
                    },
                    error: function(error) {
                        let message = error.responseJSON.errors[0].message;
                        displayToast(settings.ids.toastId, message, 3000);
                    }
                });
            }
            else
            {
                displayToast(settings.ids.toastId, "Atleast one row must be selected.", 3000);
            }
        },

        restore_selected_rows : function(options)
        {
            var settings = getSettings(options);

            var table = $(this).DataTable();

            let selectedIds = [];

            table.$('input[type="checkbox"]').each(function() {
                if (this.checked)
                {
                    selectedIds.push(this.value);
                }
            });

            let values = {
                "selected_ids": selectedIds
            }

            if (selectedIds.length != 0)
            {
                let url = settings.urls["bulkRestore"];
                if (settings.multitenancy == false)
                {
                    url += '?tenancy=false';
                }

                $.ajax({
                    url: url,
                    type: 'PATCH',
                    headers: {
                        "X-CSRF-TOKEN" : $('meta[name="csrf-token"]').attr('content')
                    },
                    data: values,
                    success: function(response) {
                        console.log(response);

                        for (let j = 0; j < response["data"].length; j++)
                        {
                            let id = response["data"][j]["attributes"]["id"];

                            let domRow = $(table.table().container()).find("#row-id-" + id).first();

                            $(domRow).fadeOut(500, function() {
                                let row = table.row("#row-id-" + id);
                                row.remove();
                                insertRow(response["data"][j], table, settings, false);
                            });
                        }
                        //table.draw(false);
                    },
                    error: function(error) {
                        let message = error.responseJSON.message;
                        displayToast(settings.ids.toastId, message, 3000);
                    }
                });
            }
            else
            {
                displayToast(settings.ids.toastId, "Atleast one row must be selected.", 3000);
            }
        }
    }

    $.fn.bulk_edit = function(methodOrOptions) {
        if (methods[methodOrOptions])
        {
            return methods[methodOrOptions].apply( this, Array.prototype.slice.call(arguments, 1));
        }
        else if (typeof methodOrOptions === 'object' || !methodOrOptions)
        {
            // Default to "init"
            return methods.init.apply(this, arguments);
        }
        else
        {
            $.error('Method ' +  methodOrOptions + ' does not exist on jQuery.bulk_edit');
        }
    };

    // Override default parameters with supplied options
    function getSettings(options)
    {
        let defaultUrls =
        {
            "bulkDelete": `/api/v1/${options.urlModel}/bulk`,
            "bulkUpdate": `/api/v1/${options.urlModel}/bulk`,
            "bulkRestore": `/api/v1/${options.urlModel}/restore/bulk`,
            "getSoftDeleted": `/api/v1/${options.urlModel}/deleted`,
            "edit": `/${options.urlModel}/edit/`,
            "view": `/${options.urlModel}/view/`,
            "delete": `/${options.urlModel}/`,
            "restore": `/${options.urlModel}/restore/`,
            "audit": `/${options.urlModel}/edit/`,
            "forceDelete": `/${options.urlModel}/force_delete/`,
            "apiRestore": `/api/v1/${options.urlModel}/restore/`,
            "apiDelete": `/api/v1/${options.urlModel}/`,
            "apiForceDelete": `/api/v1/${options.urlModel}/force_delete/`,
            "create": `/${options.urlModel}/create`,
            "apiPreview": `/api/v1/${options.urlModel}/preview/`,
            "apiBulkPreview": `/api/v1/${options.urlModel}/preview/bulk`,
        };

        let customisedUrls = $.extend(defaultUrls, options.urls)

        let defaultIds =
        {
            bulkDeleteButtonId: '#bulk-delete-button',
            bulkEditModalId: '#bulk-edit-modal',
            showSoftDeletedId: '#show-soft-deleted',
            buttonsContainerId: '#all-buttons-container',
            deleteModalId: '#delete-modal',
            restoreModalId: '#restore-modal',
            bulkUpdateButtonId: '#bulk-update-button',
            toastId: "#main-toast"
        };

        var settings = $.extend({
            ids: defaultIds,
            multitenancy: true
        }, options );

        settings['urls'] = customisedUrls;

        return settings;
    }

    // Function to initialise buttons like add, bulk update and delete
    function initButtons(settings, table)
    {
        let mainButtonsContainer = $("<div></div>");
        mainButtonsContainer.addClass("main-buttons-container");

        let createButton = $("<a class='btn btn-success main-btn btn-mr'></a>");
        if (settings.multitenancy == true)
        {
            createButton.attr("href", settings.urls.create + '/' + settings.customerId);
        }
        else
        {
            createButton.attr("href", settings.urls.create);
        }


        // We want to use language file here later
        //let capitalisedModel = settings.model.charAt(0).toUpperCase() + settings.model.substring(1);
        createButton.text("Add New");

        mainButtonsContainer.append(createButton);

        // Move bulk actions into a drop down menu.
        let bulkDropdownContainer = $('<div class="dropdown"></div>');

        let bulkButton = $("<button class='btn btn-primary main-btn btn-mr dropdown-toggle' id='dropdownMenuButton' data-toggle='dropdown'>Actions</button>");
        bulkDropdownContainer.append(bulkButton);

        let bulkMenu = $('<div class="dropdown-menu" aria-labelledby="dropdownMenuButton"></div>');
        bulkDropdownContainer.append(bulkMenu);
        mainButtonsContainer.append(bulkDropdownContainer);

        let bulkUpdateButton = null;
        let bulkDeleteForm = null;
        let bulkRestoreForm = null;
        let softDeletedButton = null;

        // Only append bulk update button if they suitable permissions.
        if (settings.permissions["edit"])
        {
            bulkUpdateButton = $('<a class="dropdown-item" type="button" data-toggle="modal">Bulk Edit</a>');
            bulkUpdateButton.attr("id", settings.ids.bulkUpdateButtonId.substring(1));
            bulkUpdateButton.attr("data-target", settings.ids.bulkEditModalId);

            bulkMenu.append(bulkUpdateButton);
        }

        // Only append bulk delete button if they suitable permissions.
        if (settings.permissions["delete"])
        {
            let bulkDeleteButton = $('<a class="dropdown-item" data-message="Are you sure you want to delete these items?" type="button" data-toggle="modal">Bulk Delete</a>');
            bulkDeleteButton.attr("id", settings.ids.bulkDeleteButtonId);
            bulkDeleteButton.attr("data-target", settings.ids.deleteModalId);

            bulkDeleteForm = $('<form class="d-inline"></form>');

            bulkDeleteForm.append(bulkDeleteButton);

            bulkMenu.append(bulkDeleteForm);
        }

        // Only append bulk restore button if they suitable permissions.
        if (settings.permissions["restore"])
        {
            let bulkRestoreButton = $('<a class="dropdown-item" data-message="Are you sure you want to restore these items?" type="button" data-toggle="modal">Bulk Restore</a>');
            bulkRestoreButton.attr("id", settings.ids.bulkRestoreButtonId);
            bulkRestoreButton.attr("data-target", settings.ids.restoreModalId);

            bulkRestoreForm = $('<form class="d-inline"></form>');

            bulkRestoreForm.append(bulkRestoreButton);
            bulkMenu.append(bulkRestoreForm);
        }

        // Only append soft deleted button if they suitable permissions.
        if (settings.permissions["audit"])
        {
            softDeletedButton = $('<a data-soft-deleted="false" class="dropdown-item">Show Soft Deleted</a>');
            softDeletedButton.attr("id", settings.ids.showSoftDeletedId.substring(1));

            bulkMenu.append(softDeletedButton);
        }

        let allButtonsContainer = $(settings.ids.buttonsContainerId);
        allButtonsContainer.append(mainButtonsContainer);

        // Add warning for bulk buttons.
        if (settings.permissions["edit"])
        {
            $(bulkUpdateButton).on('click', function (e) {
                showUnselectedBulkWarning(settings, table, e);
            });
        }

        if (settings.permissions["delete"])
        {
            $(bulkDeleteForm).on('click', function (e) {
                showUnselectedBulkWarning(settings, table, e);
            });
        }

        if (settings.permissions["audit"])
        {
            $(bulkRestoreForm).on('click', function (e) {
                showUnselectedBulkWarning(settings, table, e);
            });
        }

        // Override bulk delete form to delete separate rows
        $(bulkDeleteForm).on('submit', function (e) {
            e.preventDefault();
            $(table).bulk_edit('delete_selected_rows', settings);
        });

        // Override bulk restore form to restore separate rows
        $(bulkRestoreForm).on('submit', function (e) {
            e.preventDefault();
            $(table).bulk_edit('restore_selected_rows', settings);
        });

        // Event handler to show/hide soft deleted rows
        $(softDeletedButton).on("click", function() {

            if (!$(this).data("soft-deleted"))
            {
                $(this).text("Hide Soft Deleted");
                $(table).bulk_edit('show_soft_deleted', settings);
                $(this).data("soft-deleted", true);
            }
            else
            {
                $(this).text("Show Soft Deleted");
                $(table).bulk_edit('hide_soft_deleted');
                $(this).data("soft-deleted", false);
            }
        });
    }

    // Shows a warning when user attempts to perform a bulk action without selecting anything.
    function showUnselectedBulkWarning(settings, table, e)
    {
        let dtTable = table.dataTable();

        let selectedIds = [];

        dtTable.$('input[type="checkbox"]').each(function() {
            if (this.checked)
            {
                selectedIds.push(this.value);
            }
        });

        if (selectedIds.length == 0)
        {
            e.stopPropagation();
            displayToast(settings.ids.toastId, "Atleast one row must be selected.", 3000);
        }
    }

    function initCheckboxes(table)
    {
        // We need to insert checkboxes into the table
        // Insert master checkbox
        let checkboxHeader = $("<th><input type='checkbox' class='check-all'/></th>");
        table.find("thead tr").prepend(checkboxHeader);

        // For each row insert a checkbox
        table.find("tbody tr").each(function() {
            let subCheckbox = $("<input type='checkbox' class='sub-checkbox'/>");

            subCheckbox.attr("name", "selected_ids[]");

            let recordId = $(this).attr("id").split("-")[2];
            subCheckbox.attr("value", recordId);

            let checkboxCell = $("<td></td>");
            checkboxCell.append(subCheckbox);

            $(this).prepend(checkboxCell);
        })

        // Check/unchecks all sub checkboxes on same page when master checkbox is clicked.
        table.find('.check-all').on('click', function() {
            if (this.checked) {
                $(".sub-checkbox").prop("checked", true);
            } else {
                $(".sub-checkbox").prop("checked", false);
            }
        });

        // Checks/unchecks master checkbox when a sub checkbox is clicked.
        table.find(".sub-checkbox").on('click', function() {
            var numberOfCheckboxes = $(".sub-checkbox").length;
            var numberOfCheckboxesChecked = $('.sub-checkbox:checked').length;
            if(numberOfCheckboxes == numberOfCheckboxesChecked && numberOfCheckboxes != 0) {
                $(".check-all").prop("checked", true);
            } else {
                $(".check-all").prop("checked", false);
            }
        });
    }

    function initOverrideActions(table, settings)
    {
        // Event listener for every delete form.
        table.on("submit", '[id^="delete-form-"]', function (e) {
            e.preventDefault();
            let rawId = $(this).attr("id")
            let id = rawId.split("-")[2];
            apiDeleteRow(id, table, settings);
        });

        // Event listener for every restore form.
        table.on("submit", '[id^="undo-form-"]', function (e) {
            e.preventDefault();
            let rawId = $(this).attr("id")
            let id = rawId.split("-")[2];
            restoreRow(id, table, settings);
        });
    }

    function initPreviewDeleted(table, settings)
    {
        $(settings.ids.deleteModalId).on('show.bs.modal', function(e) {

            let button = e.relatedTarget;

            if (button.id != settings.ids.bulkDeleteButtonId)
            {
                let form = $(button).closest('form');
                let rawId = $(form).attr("id");
                let splitedId = rawId.split("-");

                if (splitedId[0] == "delete" || splitedId[0] == 'force')
                {
                    let id = splitedId[splitedId.length - 1];

                    // Send ajax request to get preview of what items will be deleted when this record is deleted.
                    $.ajax({
                        url: settings.urls["apiPreview"] + id,
                        type: 'GET',
                        headers: {
                            "X-CSRF-TOKEN" : $('meta[name="csrf-token"]').attr('content')
                        },
                        success: function(response) {
                            console.log(response);
                            let preview = generatePreview(response.data);
                            $(settings.ids.deleteModalId).find('.modal-preview').append(preview);
                        },
                        error: function(error) {
                            console.log(error)
                        }
                    });
                }
            }
            else if (button.id == settings.ids.bulkDeleteButtonId)
            {
                // Bulk delete version
                console.log("bulk delete bringer");

                let selectedIds = [];

                table.$('input[type="checkbox"]').each(function() {
                    if (this.checked)
                    {
                        selectedIds.push(this.value);
                    }
                });

                let values = {
                    "selected_ids": selectedIds
                }

                let url = settings.urls["apiBulkPreview"];
                if (settings.multitenancy == false)
                {
                    url += '?tenancy=false';
                }

                // Send ajax request to get preview of what items will be deleted when this record is deleted.
                $.ajax({
                    url: url,
                    type: 'GET',
                    headers: {
                        "X-CSRF-TOKEN" : $('meta[name="csrf-token"]').attr('content')
                    },
                    data: values,
                    success: function(response) {
                        console.log(response);
                        let preview = generateBulkPreview(response.data);
                        $(settings.ids.deleteModalId).find('.modal-preview').append(preview);
                    },
                    error: function(error) {
                        console.log(error)
                    }
                });
            }
        })
    }

    // Renders object with relationships
    function generatePreview(data)
    {
        let preview = $('<div></div>');

        if (data.relationships.length == 0)
        {
            return preview;
        }

        let message = $('<p>Items selected will be deleted. (Items belonging to selected items will automatically be deleted unless they belong to another item or reassigned.) </p>');
        preview.append(message);

        //let childrenList = $('<ul class="list-group card"></ul');
        let childrenList = $('<ul class="list-group list-group-root card"></ul');

        for (let relation in data.relationships)
        {
            //let relationshipContainer = $('<div class="relation-container"></div>');
            //let relationshipTitle = $(`<a data-toggle="collapse"><li class="list-group-item">${relation}</li></a>`);

            let childrenContainerId = `${relation}-${data.id}`;
            //relationshipTitle.attr('href', '#' + childrenContainerId);

            //let childrenContainer = $(`<div id=${childrenContainerId} class="collapse show"></div>`);

            for (let child of data.relationships[relation]) {
                renderChild(relation, child, childrenList);//childrenContainer);
            }

            //relationshipContainer.append(relationshipTitle);
            //relationshipContainer.append(childrenContainer);
            //childrenList.append(relationshipContainer);
        }

        preview.append(childrenList);

        return preview;
    }

    // Renders only top level relationships
    function generateBulkPreview(data)
    {
        let preview = $('<div></div>');

        if (data.relationships.length == 0)
        {
            return preview;
        }

        let message = $('<p>The items below will also be deleted alongside their children that do not have other owners.</p>');
        preview.append(message);

        let childrenList = $('<div class="list-group list-group-root card"></div');

        for (let relation in data.relationships)
        {
            let listElement = $('<li class="list-group-item"></li>');
            listElement.text(`You are also deleting ${data.relationships[relation].count} ${relation} alongside ${data.relationships[relation].children} items.`);
            childrenList.append(listElement);
        }

        preview.append(childrenList);

        return preview;
    }

    // Function that displays return child with full relationship and counts.
    function renderChild(relation, child, container)
    {
        let listElement = $('<li class="list-group-item"></li>');
        //let listElement = $('<a class="list-group-item"></a>');

        let checkboxContainer = $('<div class="custom-control custom-checkbox"></div>');

        let id = relation + '-' + child.id;

        let checkbox = $(`<input type="checkbox" class="custom-control-input" id="${id}">`);

        checkbox.attr("name", relation + '[]');
        checkbox.attr("value", child.id);

        if (child.deletable == false)
        {
            checkbox.attr("disabled", true);
        }

        if (child.must_delete == true)
        {
            checkbox.attr("disabled", true);
            checkbox.attr("checked", true);
        }

        checkboxContainer.append(checkbox);

        let label = $(`<label class="custom-control-label" for="${id}">Check this custom checkbox</label>`);
        label.text(`${relation} (${child.name}) alongside ${child.count} items.`);
        checkboxContainer.append(label);

        listElement.append(checkboxContainer);

        container.append(listElement);
        return;
    }

    function addModalEvents(table, settings)
    {
        let bulkEditModalId = settings.ids.bulkEditModalId;

        // Adds placeholder text for untouched bulk edit field.
        // Also adds class so client side validation doesn't activate for unchanged fields.
        $(bulkEditModalId).find('form :input').not(':input[type=submit]').each(function() {
            $(this).attr('placeholder', "Click here to edit selected items, otherwise they will retain their individual values.");
            $(this).addClass('no-validate');
        });

        // Determine if an input field has been changed to add "changed" data
        $(bulkEditModalId).find('form :input').on('change', function() {
            $(this).attr('placeholder', "");
            $(this).data("changed", true);
            $(this).removeClass('no-validate');
        });

        // Determine if an input field has been focused which counts as a change.
        $(bulkEditModalId).find('form :input').not(':input[type=submit]').on('focus', function() {
            $(this).attr('placeholder', "");
            $(this).data("changed", true);
            $(this).removeClass('no-validate');
        });

        // Reset form values when modal is closed.
        $(bulkEditModalId).on('hidden.bs.modal', function(e) {
            $(this).find('form')[0].reset();
            $("form").each(function(){
                $(this).validate().resetForm();
                $(this).find('span.invalid-feedback').remove();
            });
        });

        // Resets other things required to return form to normal apart form just values.
        $(bulkEditModalId).find('form').first().on('reset', function(e)
        {
            $(bulkEditModalId).find('form :input').not(':input[type=submit]').each(function() {
                $(this).attr('placeholder', "Click here to edit selected items, otherwise they will retain their individual values.");
                $(this).data("changed", false);
                $(this).removeClass('is-valid is-invalid');
                $(this).addClass('no-validate');
            });
        });

        // Override form submit to send a post request to api instead
        $(bulkEditModalId).find('form').first().on('submit', function(e) {
            e.preventDefault();

            let selectedIds = [];

            table.$('input[type="checkbox"]').each(function() {
                if (this.checked)
                {
                    selectedIds.push(this.value);
                }
            });

            var values = {};
            $(bulkEditModalId).find('form :input').each(function() {
                // Only get values that have changed
                if ($(this).data("changed"))
                {
                    values[$(this).attr('name')] = $(this).val();
                }

            });

            // Add selected ids array as well
            values["selected_ids"] = selectedIds;

            // If the values are valid, send api request
            if ($(this).valid())
            {
                if (selectedIds.length != 0)
                {
                    let url = settings.urls["bulkUpdate"];
                    if (settings.multitenancy == false)
                    {
                        url += '?tenancy=false';
                    }

                    $.ajax({
                        url: settings.urls["bulkUpdate"],
                        type: 'PATCH',
                        headers: {
                            "X-CSRF-TOKEN" : $('meta[name="csrf-token"]').attr('content')
                        },
                        data: values,
                        success: function(response) {
                            let data = response.data;
                            $(settings.ids.bulkEditModalId).modal('hide');

                            // Now we want to update the rows on client side
                            for (let i = 0; i < data.length; ++i) {
                                editRow(data[i], table, settings);
                            }

                            // Untick master checkbox
                            $('.check-all').prop('checked', false);

                            //table.draw(false);
                        },
                        error: function(error) {
                            console.log(error);
                            let message = error.responseJSON.errors[0].message;
                            displayToast(settings.ids.toastId, message, 3000);

                            // Display error messages in form.
                            let errors = error.responseJSON.errors;

                            for (let err in errors)
                            {
                                console.log(err, errors[err][0]);
                                let inputParent = $(bulkEditModalId).find('form :input[name=' + err.title + ']').first().parent();

                                // Delete old error messages.
                                $(inputParent).find('span.invalid-feedback').remove();

                                let errorSpan = $('<span class="invalid-feedback d-inline"></span>');
                                errorSpan.text(errors[err].message);

                                inputParent.append(errorSpan);
                            }
                        }
                    });
                }
                else
                {
                    displayToast(settings.ids.toastId, "Atleast one row must be selected.", 3000);
                }
            }
        });
    }

    function displayToast(toastId, message, duration)
    {
        let toast = $(toastId);
        let toastBody = toast.find(".toast-body").first();
        toastBody.text(message);
        toast.toast({ delay: duration });
        toast.toast('show');
    }

    function editRow(data, table, settings)
    {
        data = data["attributes"];
        let columns = settings.columns;
        let userProfile = settings.userProfile;

        let row = table.row("#row-id-" + data['id']).data();

        for (const field in data)
        {
            for (let j = 0; j < columns.length; j++)
            {
                if (field == Object.keys(columns[j])[0])
                {
                    if (columns[j][field] == "currency")
                    {
                        row[j] = convert_currency(data[field], userProfile);
                    }
                    else if (columns[j][field] == "datetime")
                    {
                        row[j] = convert_datetime(data[field], userProfile.date_format, userProfile.timezone, userProfile.locale);
                    }
                    else
                    {
                        row[j] = data[field];
                    }
                }
            }
        }

        table.row("#row-id-" + data['id']).data(row);
        row = $(table.table().container()).find("#row-id-" + data['id']).first();

        // Add event listener for delete
        // Override delete form submit and send api request instead
        $("#delete-form-" + data["id"]).on("submit", function (e) {
            e.preventDefault();
            apiDeleteRow(data["id"], table, settings);
        });

        var originalColor = row.css("background-color");

        row.delay(500).animate({
            'background-color': '#82c8e8'
        }, 750, function() {
            $(this).animate({
                'background-color': originalColor
            }, 750);
        })

    }

    function insertRow(data, table, settings, isSoftDeleted)
    {
        data = data["attributes"];
        let columns = settings.columns;

        let row = [];

        for (let i = 0; i < columns.length; i++)
        {
            let column = Object.keys(columns[i])[0];
            let type = columns[i][column];

            if (type == "checkbox")
            {
                let subCheckbox = "<input type='checkbox' class='sub-checkbox' name='selected_ids[]' value='" + data["id"] + "'/>";

                if (isSoftDeleted)
                {
                    "<input type='checkbox' class='sub-checkbox soft-deleted-checkbox' name='selected_ids[]' value='" + data["id"] + "'/>";
                }

                row.push(subCheckbox);
            }
            else if (type == "actions")
            {
                let actions = bs_crud_js(data["id"], settings, isSoftDeleted);
                row.push(actions);
            }
            else if (type == "currency")
            {
                row.push(convert_currency(data[column], settings.userProfile));
            }
            else if (type == "datetime")
            {
                let userProfile = settings.userProfile;
                row.push(convert_datetime(data[column], userProfile.date_format, userProfile.timezone, userProfile.locale));
            }
            else if (type == "boolean")
            {
                let boolean = data[column] ? "Yes" : "No";
                row.push(boolean);
            }
            else
            {
                row.push(data[column]);
            }
        }

        console.log(row);
        row = table.row.add(row).draw(false).nodes().to$().attr("id", "row-id-" + data["id"]);

        if (isSoftDeleted)
        {
            row.addClass('soft-deleted');
        }

        row.hide();
        row.fadeIn(500);

        // Add event listeners to check/uncheck master checkbox when a sub checkbox is clicked.
        row.find('.sub-checkbox').first().on('click', function() {
            var numberOfCheckboxes = $(".sub-checkbox").length;
            var numberOfCheckboxesChecked = $('.sub-checkbox:checked').length;
            if(numberOfCheckboxes == numberOfCheckboxesChecked && numberOfCheckboxes != 0) {
                $(".check-all").prop("checked", true);
            } else {
                $(".check-all").prop("checked", false);
            }
        });

        if (isSoftDeleted)
        {
            // Override undo form submit and send api request instead.
            /*table.on("submit", "#undo-form-" + data["id"], function (e) {
                e.preventDefault();
                restoreRow(data, table, settings);
            });*/

            // Override force delete submit and send an api request instead.
            table.on("submit", "#force-delete-form-" + data["id"], function (e) {
                e.preventDefault();
                apiForceDeleteRow(data["id"], table, settings);
            });
        }

        // Add event listener for delete
        /*if (!isSoftDeleted)
        {
            // Override delete form submit and send api request instead
            $("#delete-form-" + data["id"]).on("submit", function (e) {
                e.preventDefault();
                apiDeleteRow(data["id"], table, settings);
            });
        }*/
    }

    // Send ajax request to force delete record
    function apiForceDeleteRow(id, table, settings)
    {
        // Get the relationships to delete in modal preview.
        let values = {};

        $(settings.ids.deleteModalId).find('input[type="checkbox"]').each(function() {
            // If the checkbox is checked we add it to the request body to delete.
            if (this.checked == true)
            {
                let relation = this.name.substring(0, this.name.length - 2);
                if (relation in values)
                {
                    values[relation].push(this.value);
                }
                else
                {
                    values[relation] = [this.value];
                }
            }
        });

        $.ajax({
            url: settings.urls["apiForceDelete"] + id,
            type: 'DELETE',
            headers: {
                "X-CSRF-TOKEN" : $('meta[name="csrf-token"]').attr('content')
            },
            data: values,
            success: function(response) {
                console.log(response);
                deleteRow(id, table);
                //table.draw(false);
            },
            error: function(error) {
                let message = error.responseJSON.message;
                displayToast(settings.ids.toastId, message, 3000);
            }
        });
    }

    // Send ajax request to delete record
    function apiDeleteRow(id, table, settings)
    {
        // Get the relationships to delete in modal preview.
        let values = {};

        $(settings.ids.deleteModalId).find('input[type="checkbox"]').each(function() {
            // If the checkbox is checked we add it to the request body to delete.
            if (this.checked == true)
            {
                let relation = this.name.substring(0, this.name.length - 2);
                if (relation in values)
                {
                    values[relation].push(this.value);
                }
                else
                {
                    values[relation] = [this.value];
                }
            }
        });

        $.ajax({
            url: settings.urls["apiDelete"] + id,
            type: 'DELETE',
            headers: {
                "X-CSRF-TOKEN" : $('meta[name="csrf-token"]').attr('content')
            },
            data: values,
            success: function(response) {
                console.log(response);
                let showSoftDeleted = $(settings.ids.showSoftDeletedId).data("soft-deleted");

                // If the show soft deleted button is clicked we need to insert the rows we just deleted.
                if (showSoftDeleted)
                {
                    let domRow = $(table.table().container()).find("#row-id-" + id).first();

                    $(domRow).fadeOut(500, function() {
                        let row = table.row("#row-id-" + id);
                        row.remove();
                        insertRow(response["data"], table, settings, true);
                    });
                }
                else
                {
                    deleteRow(id, table);
                    //table.draw(false);
                }
            },
            error: function(error) {
                let message = error.responseJSON.message;
                displayToast(settings.ids.toastId, message, 3000);
            }
        });
    }


    function deleteRow(id, table)
    {
        let domRow = $(table.table().container()).find("#row-id-" + id);

        if (domRow.length == 0)
        {
            let row = table.row("#row-id-" + id);
            row.remove().draw(false);
        }
        else
        {
            domRow = domRow.first()
            $(domRow).fadeOut(500, function() {
                let row = table.row("#row-id-" + id);
                row.remove().draw(false);
            });
        }
    }

    function restoreRow(id, table, settings)
    {
        // Send ajax request to restore row
        console.log(settings.urls["apiRestore"] + id);
        $.ajax({
            url: settings.urls["apiRestore"] + id,
            type: 'PATCH',
            headers: {
                "X-CSRF-TOKEN" : $('meta[name="csrf-token"]').attr('content')
            },
            success: function(response) {
                // Now we want to restore the row on the client side
                //$("#undo-form-" + data["id"]).off();
                let domRow = $(table.table().container()).find("#row-id-" + id).first();

                $(domRow).fadeOut(500, function() {
                    let row = table.row("#row-id-" + id);
                    row.remove();
                    insertRow(response["data"], table, settings, false);
                });
            },
            error: function(error) {
                console.log(error)
                let message = error.responseJSON.errors[0].message;
                displayToast(settings.ids.toastId, message, 3000);
            }
        });
    }

    function convert_currency(value, userProfile)
    {
        var LocaleCurrency = require('locale-currency');
        let currencyType = LocaleCurrency.getCurrency(userProfile["locale"]);

        let valueFloat = parseFloat(value);
        let newValue = valueFloat.toLocaleString(userProfile["locale"], {style: "currency", currency: currencyType});
        return newValue;
    }

    // Mimics the blade file bs_crud.blade.php
    function bs_crud_js(id, settings, isSoftDeleted)
    {
        let permissions = settings.permissions;
        let urls = settings.urls;

        let btnToolbar = $('<div class="btn-toolbar" role="toolbar" aria-label="Edit View Delete">');

        let basicCrudGroup = $('<div class="btn-group mr-2" role="group" aria-label="View Edit Delete"></div>');

        let editButton = $('<a role="button" class="btn btn-crud waves-effect waves-themed"><i class="fal fa-edit"></i></a>');
        let viewButton = $('<a role="button" class="btn btn-crud waves-effect waves-themed"><i class="fal fa-list"></i></a>');
        let deleteButton = $('<a role="button" class="btn btn-crud no-left-border-radius waves-effect waves-themed" type="button" data-toggle="modal" data-target="#delete-modal"><i class="fal fa-trash-alt"></i></a>');

        deleteButton.attr("data-message", "Are you sure you want to permanently delete this item?");

        if (permissions["edit"] == false || isSoftDeleted)
        {
            editButton.attr("disabled", "disabled");
            editButton.attr("aria-disabled", true);
            editButton.prop("disabled", true);
            editButton.addClass("disabled");
        }

        if (permissions["view"] == false)
        {
            viewButton.attr("disabled", "disabled");
            viewButton.attr("aria-disabled", true);
            viewButton.prop("disabled", true);
            viewButton.addClass("disabled");
        }

        if (permissions["delete"] == false)
        {
            deleteButton.attr("disabled", "disabled");
            deleteButton.attr("aria-disabled", true);
            deleteButton.prop("disabled", true);
            deleteButton.addClass("disabled");
        }

        editButton.attr("href", urls["edit"] + id);
        viewButton.attr("href", urls["view"] + id);

        let deleteForm = $('<form method="post"></form>');
        deleteForm.attr("id", "delete-form-" + id);
        deleteForm.attr("action", urls["delete"] + id);

        let csrfField = $('<input type="hidden"/>');
        csrfField.attr("name", "_token");
        csrfField.attr("value", $('meta[name="csrf-token"]').attr('content'));
        deleteForm.append(csrfField);

        let deleteField = $('<input type="hidden" name="_method" value="DELETE"/>');
        deleteForm.append(deleteField);

        deleteForm.append(deleteButton);

        // Add force delete button if the record is soft deleted
        if (isSoftDeleted)
        {
            deleteButton.attr("data-message", "Are you sure you want to permanently delete this item?");
            deleteForm.attr("action", urls["forceDelete"] + id);
            deleteForm.attr("id", "force-delete-form-" + id);
            deleteButton.removeClass("btn-crud");
            deleteButton.addClass("btn-force-delete");
        }

        basicCrudGroup.append(editButton);
        basicCrudGroup.append(viewButton);
        basicCrudGroup.append(deleteForm);

        btnToolbar.append(basicCrudGroup);

        let restoreCrudGroup = $('<div class="btn-group mr-2" role="group" aria-label="Restore"></div>');
        let undoButton = $('<a role="button" class="btn btn-secondary no-right-border-radius text-white waves-effect waves-themed" type="button" data-toggle="modal"><i class="fal fa-undo"></i></a>');
        let eyeButton = $('<a role="button" class="btn btn-secondary text-white waves-effect waves-themed"><i class="fal fa-eye"></i></a>');

        undoButton.attr("data-target", settings.ids.restoreModalId);

        let undoForm = $('<form method="post"></form>');
        let undoFormId = "undo-form-" + id;
        undoForm.attr("id", undoFormId);
        //undoForm.attr("action", urls["restore"] + id);

        //undoForm.append(csrfField.clone());

        //patchField = $('<input type="hidden" name="_method" value="PATCH"/>');
        //undoForm.append(patchField);

        undoForm.append(undoButton);

        if (!isSoftDeleted)
        {
            // Disabled restore for items not soft deleted
            undoButton.attr("disabled", "disabled");
            undoButton.attr("aria-disabled", true);
            undoButton.prop("disabled", true);
            undoButton.addClass("disabled");
        }

        // Disable eye button for now
        eyeButton.attr("disabled", "disabled");
        eyeButton.attr("aria-disabled", true);
        eyeButton.prop("disabled", true);
        eyeButton.addClass("disabled");

        restoreCrudGroup.append(undoForm);
        restoreCrudGroup.append(eyeButton);

        btnToolbar.append(restoreCrudGroup);

        return btnToolbar[0].outerHTML;
    }

}( jQuery ));
