(function ( $ ) {
    $.fn.quote_summary = function(options = []) {
        let settings = $.extend({
            selectors: [],
            events: [],
            buttonText: 'Create'
        }, options );

        console.log('Init quote summary');
        initQuoteSummary($(this), settings);

        function initQuoteSummary(form, settings)
        {
            $('.quote-summary-footer').removeClass('d-none');

            // Make the buttons submit the form.
            initButtons(form, settings);

            $('.btn-quote-summary').on('click', function(e) {
                //e.preventDefault();
                console.log('get summary');
                updateQuoteSummary(form, settings);
            });

            // Need to add event listeners such that when quote pricing changes we update the quote summary.
            form.on('input', 'input[name="setup_fee_overrides[]"], input[name="price_overrides[]"]', function() {
                updateQuoteSummary(form, settings);
            });

            form.on('change', '[name="product_price_ids[]"]', function() {
                updateQuoteSummary(form, settings);
            });

            /*form.on('change', 'select[name^="contract_length_ids"]', function() {
                updateQuoteSummary(form, settings);
            });

            form.on('change', 'select[name^="billing_cycle_ids"]', function() {
                updateQuoteSummary(form, settings);
            });*/

            let formId = form.attr("id");

            console.log('init quote summary mutation observer');
            // First mutation observer check if the order line items are added to the DOM.
            // https://stackoverflow.com/a/29540461
            // https://javascript.info/mutation-observer
            (new MutationObserver(function(mutations) {
                for (let mutation of mutations) {
                    let addedNodes = mutation.addedNodes;
                    for (let addedNode of addedNodes)
                    {
                        // we track only elements, skip other nodes (e.g. text nodes)
                        if (!(addedNode instanceof HTMLElement)) continue;

                        // For adding an order item.
                        if ($(addedNode).hasClass("product-type-extra-fields"))
                        {
                            updateQuoteSummary(form, settings);
                        }
                        // For the initial loading.
                        else if ($(addedNode).hasClass("order-items-container"))
                        {
                            updateQuoteSummary(form, settings);
                        }
                    }
                }
            })).observe(document.querySelector('#' + formId), {childList: true, characterData: true, subtree: true});
        }

        function initButtons(form, settings)
        {
            $('#quote-summary-submit-btn').text(settings.buttonText);

            $('#quote-summary-submit-btn').on('click', function() {
                //console.log(form);
                //console.log('submit footer');
                form.submit();
            });
        }

        function updateQuoteSummary(form, settings)
        {
            let body = 'calculate=1&' + form.serialize();
            let url = form.attr("action");

            $.ajax({
                url: url,
                type: 'POST',
                headers: {
                    "X-CSRF-TOKEN" : $('meta[name="csrf-token"]').attr('content')
                },
                data: body,
                success: function(response) {
                    console.log(response);

                    // Populate recurring costs container.
                    let container = $('.recurring-costs-containers');
                    container.empty();

                    let totalSetupFee = response.setupFees;
                    let roundedTotalSetupFee = convert_currency(totalSetupFee, settings.userProfile);
                    let entry = $(`<p>${roundedTotalSetupFee} Total Setup Fee</p>`);
                    container.append(entry);

                    let recurringCosts = response.recurringCosts;

                    for (const [period, amount] of Object.entries(recurringCosts)) {
                        // Epsilon for rounding, https://stackoverflow.com/a/11832950
                        //let rounded = (parseFloat(amount) + Number.EPSILON).toFixed(2);
                        let rounded = convert_currency(amount, settings.userProfile);

                        let entry = $(`<p>${rounded} ${period}</p>`);
                        container.append(entry);
                    }

                    // Populate quote summary at bottom of page.
                    let quoteSummary = $('.quote-summary-pricing');
                    quoteSummary.empty();

                    let message = '';
                    let first = true;

                    for (const [period, amount] of Object.entries(recurringCosts)) {
                        // Epsilon for rounding, https://stackoverflow.com/a/11832950
                        //let rounded = (parseFloat(amount) + Number.EPSILON).toFixed(2);
                        let rounded = convert_currency(amount, settings.userProfile);

                        if (first === true)
                        {
                            message += `${rounded} ${period}`;
                        }
                        else
                        {
                            message += ` + ${rounded} ${period}`;
                        }

                        first = false;
                    }

                    if (first === true)
                    {
                        message += `${roundedTotalSetupFee} Total Setup Fee`
                    }
                    else
                    {
                        message += ` + ${roundedTotalSetupFee} Total Setup Fee`
                    }

                    quoteSummary.text(message);

                },
                error: function(error) {
                    console.log(error);
                }
            });
        }

        function convert_currency(value, userProfile)
        {
            var LocaleCurrency = require('locale-currency');
            let currencyType = LocaleCurrency.getCurrency(userProfile["locale"]);

            let valueFloat = parseFloat(value);
            let newValue = valueFloat.toLocaleString(userProfile["locale"], {style: "currency", currency: currencyType});
            return newValue;
        }
    }
}( jQuery ));





