import { bs_input_dropdown_js } from './js_blade_components.js';

(function ( $ ) {
    $.fn.quote_warning = function(options = []) {
        let settings = $.extend({
            submitButtonId: '#quote-summary-submit-btn',
            modalId: '#generic-modal',
            button1: '#generic-btn1',
            button2: '#generic-btn2',
            users: [],
        }, options );

        initQuoteWarning($(this), settings);

        function initQuoteWarning(form, settings)
        {
            console.log("Init quote warning.")
            console.log(settings.submitButtonId);

            let submitButton = $(settings.submitButtonId);

            // Add event listener to submit the form.
            form.on('submit', function(e) {
                e.preventDefault();
                checkWarning(form, settings);
            });
        }

        function checkWarning(form, settings)
        {
            console.log("submit has been prevented by quote warning module");
            console.log("submit the form with an additional parameter in the query string");

            // Determine if the form is PUT request or just a POST request.
            let methodHiddenInput = form.find('input[name="_method"]');
            let method = 'POST'
            if (methodHiddenInput.length > 0)
            {
                method = methodHiddenInput.attr("value");
            }

            let body = "";
            let url = "";

            if (method === 'POST')
            {
                body = form.serialize();
                url = '/admin/quotes/warnings';
            }
            else if (method === 'PUT')
            {
                body = 'check_warning=1&' + form.serialize();
                url = form.attr("action");
            }

            let usersUrl ='/api/v1/users?filter[roles.permissions.name]=approve.quotes';

            $.when(
                $.ajax({
                    url: url,
                    type: 'POST',
                    headers: {
                        "X-CSRF-TOKEN" : $('meta[name="csrf-token"]').attr('content')
                    },
                    data: body,
                }),
                $.ajax({
                    url: usersUrl,
                    type: 'GET',
                    headers: {
                        "X-CSRF-TOKEN" : $('meta[name="csrf-token"]').attr('content')
                    },
                })
            ).then(function (warningResponse, usersResponse) {
                console.log(warningResponse[0]);
                console.log(usersResponse[0]);
                displayModal(form, settings, warningResponse[0], usersResponse[0]);
            });


        }

        function displayModal(form, settings, response, usersResponse)
        {
            let modal = $(settings.modalId);
            let modalBody = modal.find('.modal-body');

            modalBody.empty();
            modal.find('.modal-title').text('Warning');

            if (response.legacy_warning !== false)
            {
                let warning = $(`<p>You have modified an order that uses legacy pricing.
                Once this is changed, the legacy price option will not be able to be retrieved. Would you like to continue?</p>`);
                modalBody.append(warning);
            }

            if (response.elevated === true)
            {
                displayElevatedModalContent(form, settings, response);
            }
            else
            {
                if (response.reapproval_warning !== false)
                {
                    let warning = $(`<p>You have modified a field that will affect existing approvals on this quote.
                    Once this is changed, existing approvals will be invalidated and a new approval request will be generated. Would you like to continue?</p>`);
                    modalBody.append(warning);
                }
                else if (response.new_approval_warning !== false)
                {
                    let warning = $(`<p>You have modified a price override field. Because you lack the permissions, an
                    approval request will be generated, preventing the quote from being submitted until it is accepted. Would you like to continue?</p>`);
                    modalBody.append(warning);
                }

                if (response.legacy_warning === false && response.reapproval_warning === false && response.new_approval_warning === false)
                {
                    // Just submit the form if there are no warnings.
                    form.unbind('submit');
                    form.submit();
                }
                else
                {
                    // Add a textarea to supply reason for requesting the approval.
                    if (response.reapproval_warning !== false || response.new_approval_warning !== false)
                    {
                        displayApprovalDescription(modalBody, response);

                        let users = [];

                        for (let i = 0; i < usersResponse.data.length; i++)
                        {
                            let user =  {};
                            user.key = usersResponse.data[i].attributes.id;
                            user.value = usersResponse.data[i].attributes.name;
                            users.push(user);
                        }

                        let notify = bs_input_dropdown_js('Select users to notify', 'notify[]', 'Select user to notify', users);
                        notify.find('select').attr('multiple', '');
                        notify.find('select').attr('form', form.attr("id"));
                        modalBody.append(notify);
                        notify.find('select').attr('form', form.attr("id")).select2({
                            //theme: 'bootstrap4',
                            dropdownParent: modal
                        });
                        notify.find('[data-toggle="tooltip"]').tooltip();

                        let requestDescription = bs_input_textarea_js('Reason for request', 'request_description', 'test tooltip');
                        // Add a form attribute so it gets posted.
                        requestDescription.find('textarea').attr('form', form.attr("id"));

                        modalBody.append(requestDescription);
                        requestDescription.find('[data-toggle="tooltip"]').tooltip();
                    }

                    let modalFooter = modal.find('.modal-footer');
                    modalFooter.empty();

                    // Add modal button1
                    let cancelButton = $(`<button type="button" class="btn btn-secondary waves-effect waves-themed">Cancel</button>`)
                    modalFooter.append(cancelButton);

                    // Hide the modal on click.
                    cancelButton.on('click', function(e) {
                        modal.modal('hide');
                    });

                    // Add modal button2
                    let continueButton = $(`<button type="submit" class="btn btn-primary waves-effect waves-themed">Continue</button>`)
                    modalFooter.append(continueButton);

                    // Add event listener to submit the form.
                    continueButton.on('click', function(e) {
                        form.unbind('submit');
                        form.submit();
                    });

                    console.log(modal);
                    modal.modal('show');
                }
            }
        }

        function displayElevatedModalContent(form, settings, response)
        {
            let modal = $(settings.modalId);
            let modalBody = modal.find('.modal-body');

            if (response.reapproval_warning !== false)
            {
                let warning = $(`<p>You have modified a field that will affect existing approvals on this quote.
                Once this is changed, existing approvals will be invalidated and a new approval will be generated.
                Since you have elevated permissions the approval is automatically resolved.
                Would you like to continue?</p>`);
                modalBody.append(warning);
            }
            else if (response.new_approval_warning !== false)
            {
                let warning = $(`<p>You have modified a price override field. An approval
                will be generated.
                Since you have elevated permissions the approval is automatically resolved.
                Would you like to continue?</p>`);
                modalBody.append(warning);
            }

            if (response.legacy_warning === false && response.reapproval_warning === false && response.new_approval_warning === false)
            {
                // Just submit the form if there are no warnings.
                form.unbind('submit');
                form.submit();
            }
            else
            {
                if (response.reapproval_warning !== false || response.new_approval_warning !== false)
                {
                    displayApprovalDescription(modalBody, response);

                    let resolutionDescription = bs_input_textarea_js('Reason for approval', 'resolution_description', 'test tooltip');
                    // Add a form attribute so it gets posted.
                    resolutionDescription.find('textarea').attr('form', form.attr("id"));

                    modalBody.append(resolutionDescription);
                    resolutionDescription.find('[data-toggle="tooltip"]').tooltip();
                }

                let modalFooter = modal.find('.modal-footer');
                modalFooter.empty();

                // Add modal button1
                let cancelButton = $(`<button type="button" class="btn btn-secondary waves-effect waves-themed">Cancel</button>`)
                modalFooter.append(cancelButton);

                // Hide the modal on click.
                cancelButton.on('click', function(e) {
                    modal.modal('hide');
                });

                // Add modal button2
                let continueButton = $(`<button type="submit" class="btn btn-primary waves-effect waves-themed">Continue</button>`)
                modalFooter.append(continueButton);

                // Add event listener to submit the form.
                continueButton.on('click', function(e) {
                    form.unbind('submit');
                    form.submit();
                });

                console.log(modal);
                modal.modal('show');
            }
        }

        function displayApprovalDescription(modalBody, response)
        {
            let description = '';
            if (response.reapproval_warning !== false)
            {
                description = response.reapproval_warning;
            }
            else if (response.new_approval_warning !== false)
            {
                description = response.new_approval_warning;
            }

            let descriptionTitle = $(`<p>Description</p>`);
            modalBody.append(descriptionTitle);

            let approvalDescription = $(`<ul></ul>`);
            let splitted = description.split("\n");
            for (let i = 0; i < splitted.length; i++)
            {
                let descriptionPart = $(`<li>${splitted[i]}</li>`);
                approvalDescription.append(descriptionPart);
            }

            modalBody.append(approvalDescription);
        }

        function bs_input_textarea_js(labelName, name, tooltip)
        {
            let textarea = $(`
            <div class="form-group col pl-0">
                <label for="${name}">${labelName} <i class="fal fa-info-circle" data-toggle="tooltip" data-placement="top" title="${tooltip}"></i></label>
                <textarea class='form-control' name="${name}" type='text'></textarea>
            </div>`)

            return textarea;
        }
    }
}( jQuery ));





