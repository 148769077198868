//require('./bootstrap');

require('./modal');

require('./bulk_edit');

require('./audit_log');

require('./tooltips');

require('./customer_dropdown');

require('./toast');

require('./crud');

require('./load_partial');

require('./order_create');

require('./basic_datatables');

require('./quotes_summary');

require('./service_summary');

require('./quote_warning');

require('./approvals.js');

require('./submit_quote.js');
